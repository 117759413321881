<template>
    <div class="box">
        <Header type="expert" :logo="logoUrl" :type="'search'">
            <div slot="search" style="display: none"></div>
        </Header>

        <div class="content">
            <div class="title">省市医学会委员任职库</div>
            <div class="search-box">
                <div class="search-type-items">
                    <div :class="['item' , index == typeCurrentIndex && 'active']" v-for="(item,index) in searchTypes" @click="handleChangeType(item,index)" :key="index">{{ item.title }}</div>
                </div>
                <div class="search">
                    <div class="search-type flex x-left" v-if="typeCurrentIndex == 0">
                        <a-input placeholder="请输入专家姓名" v-model="expertName" style="width: 550px" />
                        <a-button type="primary" @click="handlePersonSearch">
                            搜索
                        </a-button>
                    </div>
                    <div class="search-type flex x-left" v-if="typeCurrentIndex == 1">
                        <a-select placeholder="请选择省/市医学会" style="width: 550px;height: 42px" @change="handleAddressChange" show-search :filter-option="filterOption" v-model="addressValue">
                            <a-select-option :value="index" v-for="(item,index) in pickerGather.address" :key="index">
                                {{ item.province_name }}
                            </a-select-option>
                        </a-select>
                        <a-button type="primary" @click="handleAddressChange(addressValue)">
                            搜索
                        </a-button>
                    </div>
                    <div class="search-type flex x-left" v-if="typeCurrentIndex == 2">
                        <a-select placeholder="选择专科分会" style="width: 550px;height: 42px" show-search  :filter-option="filterOption" @change="handleSubjectChange" v-model="subjectValue">
                            <a-select-option :value="item.id" v-for="(item,index) in pickerGather.subject" :key="index">
                                {{ item.title }}
                            </a-select-option>
                        </a-select>
                        <a-button type="primary" @click="handleSubjectChange(subjectValue)">
                            搜索
                        </a-button>
                    </div>
                </div>
            </div>

        </div>
        <div class="c-bto">
            <img src="~@/assets/images/jobWarehouse-bto-bg.png" alt="">
        </div>

        <Footer style="position: absolute;bottom: 0;width: 100%"></Footer>
        <branchListDrawer :visible="visible" :title="drawerName" :list="branchList" @handleClose="handleClose"></branchListDrawer>
    </div>
</template>

<script>
import Header from '@/components/layout/layout-header';
import {exportLogo} from "@/config/const";
import Footer from "@/components/layout/layout-footer.vue";
import branchListDrawer from "@/views/jobWarehouse/components/branchListDrawer.vue";
export default {
    name: "index",
    components : {
        Header,
        Footer,
        branchListDrawer
    },
    data(){
        return{
            logoUrl: exportLogo,
            searchTypes : [
                {
                    title : '搜专家',
                    type : 'expert'
                },
                {
                    title : '省/市学会',
                    type : 'society'
                },
                {
                    title : '专科分会',
                    type : 'specialty'
                }
            ],
            typeCurrentIndex : 0 ,// 当前选中的搜索类型下标
            pageType : 'expert',//当前搜索类型
            pickerGather : {
                address : [],
                subject : []
            },
            expertName : '',// 专家名称
            addressValue: undefined,// 地址选择器值
            subjectValue: undefined,// 专科选择器值
            visible : false,//学会列表弹窗
            drawerName : '',// 学会列表弹窗名称
            branchList : [],// 学会列表
        }
    },
    created() {
        // this.getPickerData('address'); //获取省市区学会下拉
        this.nextPageParams = {};
    },
    methods : {
        onSearch(value) {
            console.log(value);
        },
        handleChangeType(item,index){
            this.typeCurrentIndex = index;
            this.pageType = item.type;
            if(item.type == 'society'){
                this.getPickerData('address')
            }else if(item.type == 'specialty'){
                this.getPickerData('subject');
            }
        },
        handleAddressChange(index){
            let data = this.pickerGather.address[index];
            let addressItem = data,
                count = addressItem.count,
                id = count > 1 ? addressItem.id : addressItem.list.committee_id
                name = count > 1 ? addressItem.province_name :addressItem.list.name;
            let url = '',query = {};
            if( count > 1){
                /**** replace ***/
                // url = `/search-pages/branch-list/branch-list?id=${id}&name=${name}&keyword=${RealName}`
                this.drawerName = name;
                this.request.post('BranchList', { province_id : id}).then(res => {
                    this.branchList = res.data;
                    this.visible = true;
                })
            }else{
                url = '/job-warehouse/branchHome';
                query = {
                    id,
                    name,
                }
            }
            this.$router.push({
                path : url,
                query
            })

        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        handleSubjectChange(value){
            let data = this.pickerGather.subject.filter(item => item.id == value)[0];
            this.$router.push({
                path : '/job-warehouse/branchVenueHome',
                query : {
                    id : data.id,
                    name : data.title
                }
            })
        },
        // 获取下拉菜单
        getPickerData(type){
            let data = this.pickerGather[type];
            let query_type = type == 'address' ? 1 : 2;
            if(type !== 'person' && !Object.keys(data).length){
                this.request.post('ProvinceSpecialty', { query_type }).then(res => {
                    if(type == 'address'){
                        this.pickerGather[type] = res.data;
                    }else{
                        let json = [];
                        for(let k in res.data){
                            json.push(
                                {
                                    id : k,
                                    title : res.data[k]
                                }
                            )
                        }
                        this.pickerGather[type] = json;
                    }
                })
            }
        },
        // 搜专家
        handlePersonSearch(){
            let value = this.expertName;
            if(!value) return;
            this.$router.push({
                path : '/job-warehouse/expertHome',
                query : {
                    expertName : value
                }
            })
        },
        handleClose(){
            this.visible = false;
        }
    }
}
</script>

<style scoped lang="less">
    .box{
        background: url("~@/assets/images/jobWarehouse-top-bg.png") no-repeat top center;
        background-size: 100% 100%;
        height: 100vh;
        position: relative;
        .content {
            min-height: 500px;
            width: 1240px;
            margin: 0 auto;
            padding: 200px 20px 28px;
            .title{
                color: #004DA1;
                font-size: 22px;
                font-weight: bold;
                line-height: 33px;
                text-align: center;
            }
            .search-box{
                width: 650px;
                margin: 30px auto;
                .search-type-items{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 10px;
                    .item{
                        width: 88px;
                        height: 30px;
                        background: #FAFBFF;
                        border-radius: 4px;
                        border: 1px solid #F5F5F5;
                        color: #333333;
                        font-size: 14px;
                        line-height: 30px;
                        margin-right: 10px;
                        cursor: pointer;
                        &.active{
                            background: #1E51C9;
                            color: #fff;
                            font-weight: bold;
                            border: 1px solid #C7D7FF;
                        }
                    }
                }
                .search{
                    .search-type{
                        height: 40px;
                        input , button , select , /deep/ .ant-select-selection{
                            height: 100%;
                        }
                        input , select , /deep/ .ant-select-selection{
                            width: 550px;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                        button{
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            background: #1E51C9;
                            border-color: #1E51C9;
                            width: 100px;
                        }
                        /deep/ .ant-select-selection__rendered{
                            height: 100%;
                        }
                    }
                }
            }
        }
        .c-bto{
            width: 100%;
            position: absolute;
            bottom: 0;
            z-index: -10;
            img{
                width: 100%;
                display: block;
            }
        }
    }
    /deep/ .ant-select-selection-selected-value{
        line-height: 40px;
    }
</style>