<template>
    <div>
        <a-drawer
            :title="title + '下学/协会学术组织'"
            placement="right"
            width="400"
            :closable="false"
            :visible="visible"
            @close="close"
        >
            <div class="list-items">
                <div class="item flex y-center x-between" v-for="(item,index) in list" :key="index" @click="handleToNextPage(item)">
                    <div class="name">{{ item.name }}</div>
                    <div class="med med-right"></div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
export default {
    name: "branchListDrawer",
    props : {
        visible : {
            type : Boolean,
            default : false
        },
        title : {
            type : String,
            default : ''
        },
        list : {
            type : Array,
            default : () => []
        }
    },
    methods : {
        close(){
            this.$emit('handleClose');
        },
        handleToNextPage(item){
            let { committee_id : id, name } = item;
            this.$router.push({
                path : '/job-warehouse/branchHome',
                query : {
                    id,
                    name,
                }
            })
            this.close();
        }
    },
}
</script>

<style scoped lang="less">
    .list-items{
        .item{
            cursor: pointer;
            background: #f8fbfd;
            border-radius: 5px;
            border: 1px solid #edeef4;
            padding: 0 15px;
            color: #333;
            font-size: 14px;
            height: 50px;
            margin-bottom: 20px;
        }
    }
</style>